import axios, { HttpStatusCode } from "axios";
import urlJoin from "url-join"; 
import api from "../constants/api";

export class Login {
    static async login(email, password) {
        const url = urlJoin(api.host, api.base, api.login);
        //const url = "https://serviciosecologicos.com/api/users/login";
        try{ 
            //const response = await axios.post(url, {email, password}, {headers: {"User-Agent": api.userAgent}});
            const response = await axios.post(url, {email, password});
            if(response.status > HttpStatusCode.BadRequest){
                throw response.data;
            }
            return response.data; 
             
        } catch(error){
            return false;
        }
        /* try{ 
            const response = await axios.post(url, {email, password}, { headers: {"User-Agent": 'KCS-Client V1.0.0' , 'Access-Control-Allow-Origin': '*', 'origin':'x-requested-with', 'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin,privatekey', 'Content-Type': 'application/json'}, withCredentials: true});

            console.log(response)

            /*const response = await fetch(url, {method: "POST", body: JSON.stringify({email, password}), headers: {'Content-Type': 'application/json'}, mode: "no-cors"})
            console.log(response)
            const data = await response.text()
            console.log(data)*/
             
         
    }

    static async resetPass(email){
        const url = urlJoin(api.host, api.base, api.reset);
        try{ 
            const response = await axios.post(url, {email}, {headers: {"User-Agent": api.userAgent}});
            if(response.status > HttpStatusCode.BadRequest){
                throw response.data;
            }
            return response.data; 
             
        } catch(error){
            return false;
        }
    }

    /**
     * Manda a cambiar la contraseña desde la pantalla de recuperación que se accede
     * desde el email para recuperar la pass.
     * La url es del estilo: 
     * ```
     * users/reset-password/mqUn2KmVgIbw2utA/93e9fb2c447dc12e0c0b5b0740b8c0fc0d83c8ce
     *                     ───────────────── ────────────────────────────────────────
     *                           ↑ token                    ↑ sha
     * ```
     * @param {string} token el id de la petición que se recibe
     * @param {string} sha digest del usuario al que se le cambiará la contraseña
     * @param {string} password contraseña
     * @param {string} password2 verificación de la contraseña
     * @returns {Promise<any>} objeto de respuesta
     */
    static async setNewPass(token, sha, password, password2){
        const url = urlJoin(api.host, api.base, api.changePass, token, sha);
        try{ 
            const response = await axios.patch(url, {password, password2}, {headers: {"User-Agent": api.userAgent}});
            if(response.status > HttpStatusCode.BadRequest){
                throw response.data;
            }
            return response.data; 
             
        } catch(error){
            return false;
        }
    }
}