import { Logger } from "@kcram-solutions/logger";
import axios, { HttpStatusCode } from "axios";
import urlJoin from "url-join";
import api  from "../constants/api";
import storageKeys from "../constants/storageKeys";
import { recuperar } from "../helpers/local_storage";
import { searchInArray } from "../helpers/objects";


const logger = new Logger('etiquetas');

export class Etiquetas {
    static async list(page = 1, limit = 6) {
        logger.log('Page', page);
        const url = urlJoin(api.host, api.base, api.etiquetas + `?page=${page}&limit=${limit}`);
        logger.log('Url', url);
        try{ 
            const response = await axios.get(url, { 
                headers: {
                    //"User-Agent": api.userAgent,
                    "Authorization": "Bearer " + recuperar(storageKeys.user.token)
                }
            });
            if(response.status > HttpStatusCode.BadRequest){
                throw response.data;
            }
            return response.data; 
           
        } catch(error){
            return false;
        }
    }

    static async delete(id, residuo){
        const url = urlJoin(api.host, api.base, api.etiquetas, id);
        try{ 
            const response = await axios({
                url,
                headers: {
                    //"User-Agent": api.userAgent,
                    'Content-type': 'application/json; charset=UTF-8',
                    "Authorization": "Bearer " + recuperar(storageKeys.user.token)  
                },
                data: {nombre_residuo: residuo},
                method: 'DELETE'
            });
            if(response.status > HttpStatusCode.BadRequest){
                throw response.data;
            }
            return response.data; 
           
        } catch(error){
            return false;
        }
    }
    static async getOne(id){
        const url = urlJoin(api.host, api.base, api.etiquetas, id);
        try{ 
            const response = await axios({
                url,
                headers: {
                    //"User-Agent": api.userAgent,
                    'Content-type': 'application/json; charset=UTF-8',
                    "Authorization": "Bearer " + recuperar(storageKeys.user.token)  
                },
                method: 'GET'
            });
            if(response.status > HttpStatusCode.BadRequest){
                throw response.data;
            }
            return response.data; 
           
        } catch(error){
            return false;
        }
    }

    static async getPDF(id){
        const url = urlJoin(api.host, api.base, api.etiqueta.pdf, id);
        try{ 
            const response = await axios({
                url,
                headers: {
                    //"User-Agent": api.userAgent,
                    'Content-type': 'application/json; charset=UTF-8',
                    "Authorization": "Bearer " + recuperar(storageKeys.user.token)  
                },
                method: 'GET'
            });
            if(response.status > HttpStatusCode.BadRequest){
                throw response.data;
            }
            return response.data; 
           
        } catch(error){
            return false;
        }
    }

    static async getImage(id){
        const url = urlJoin(api.host, api.base, api.etiqueta.imagen, id);
        try{ 
            const response = await axios({
                url,
                headers: {
                    //"User-Agent": api.userAgent,
                    'Content-type': 'application/json; charset=UTF-8',
                    "Authorization": "Bearer " + recuperar(storageKeys.user.token)  
                },
                method: 'GET'
            });
            if(response.status > HttpStatusCode.BadRequest){
                throw response.data;
            }
            return response.data; 
           
        } catch(error){
            return false;
        }
    }
}